$blue: #0067ac;
$green: #16975f;
$red: #d0021b;
$orange: #ee7410;
$text-color: #2e384d;
$gray: #606d7c;
$dark-gray: #2e384d;
$light-gray: #b0bac9;
$lightly-gray: #bfc5d2;
$lightsteelblue: #90a6d5;
$darkcyan: #00a9ee;
$charcoal-grey: #647283;
