@charset "utf-8";

// import variables
@import "colors";
@import "variables";

// base style
@import "base/color";
@import "base/variable";
@import "base/header";
@import "base/menu";
@import "base/sideber";
@import "base/body";

// component style
@import "component/button";
@import "component/components";
@import "component/modal";

// module style
@import "module/search";
@import "module/dashboard";
@import "module/task.scss";

// responsive style
@import "responsive";

// Utilities
@import "utility";

// Normalize
@import "normalize/variables";
@import "normalize/vertical-rhythm";
@import "normalize/normalize-mixin";

@include normalize();

@import "~react-big-calendar/lib/addons/dragAndDrop/styles.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";

@import "./grid";
@import "./mixins";
@import "./rbc.scss";

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.0/css/all.min.css");

// @import "~antd/lib/style/themes/default.css";
// @import "./antdcolors.less";
@import "~react-calendar/dist/Calendar.css";

// Components:
@import "component/two-factor-verification";

.ant-form-item {
    margin: 0 !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
    text-align: left;
    margin-bottom: -15px;
}

.fake-input {
    height: 36px;
    border: 1.5px solid #f0f0f0;
    background: #ffffff;
    border-radius: 4px;
    padding: 0 3px;
    font-family: "Arimo", sans-serif;
}

.logo-image {
    max-height: 35px;
}

.theme-green {
    color: $green;
}

.label-desc {
    color: $blur-color;
    font-weight: $fw-medium;
}

.text-muted .ant-badge-status-text {
    color: #6c757d !important;
}
