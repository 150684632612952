// colors
$primary-color: #ffffff;
$secondary-color: #fafbfb;
$blur-color: #002257;
$blur-color-menu: #001940;
$blur-color2: #0079ff;
$blur-color3: #016bff;
$green-color: #16975f;
$light-blue: #7d94b8;
$gray-color: #eaf0f7;
$gray-color2: #f0f1f2;
$gray-color3: #979797;
$gray-color4: #f7f8f9;
$gray-color5: #dfe1e4;
$gray-color6: #dcdfe1;
$gray-color7: #292929;
$deep-gray: #555555;
$header-border-color: #eaeaea;
$light-gray: #a5afbe;
$highlited-blue: #439cff;
$menu-green: #eef4f4;
$menu-gray: #eae8e7;
$yellow-primary: #ffa62c;
$checkbox-border: #aeb8c5;
$disavled-color: #97a3b4;
$input-border-color: #f0f0f0;
$light-blue2: #9cadbf;
$light-blue3: #8698ad;
$light-blue4: #9ca3ad;
$yellow-color: #ffa62c;

// :root {
//   --background-color: $secondary-color;
// }
$bg-color: $secondary-color;
