@charset "utf-8";

// import variables
@import "../../../styles/colors";
@import "../../../styles/variables";

// base style
@import "../../../styles/base/color";
@import "../../../styles/base/variable";
@import "../../../styles/base/header";
@import "../../../styles/base/menu";
@import "../../../styles/base/sideber";
@import "../../../styles/base/body";

// component style
@import "../../../styles/component/button";
@import "../../../styles/component/components";
@import "../../../styles/component/modal";

// module style
@import "../../../styles/module/search";
@import "../../../styles/module/dashboard";
@import "../../../styles/module/task.scss";

// responsive style
@import "../../../styles/responsive";

// Utilities
@import "../../../styles/utility";

@media (min-width: 1200px) {
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 15.28% !important;
    max-width: 15.28% !important;
  }
}
.reportss {
  background: #d9d9d9 !important;
  height: fit-content;
}
.showclass {
  box-shadow:
    20px 3px 0 #eae8e7 inset,
    20px 8px 0 #eae8e7;
}
.report-top-para {
  font-weight: bold;
  padding-left: 14%;
  padding-bottom: 20px;
}
.pheight {
  height: 24px;
}
.reportss .ant-btn > span {
  font-weight: bold;
}
