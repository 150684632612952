@charset "utf-8";

// import variables
@import "../../../styles/colors";
@import "../../../styles/variables";

// base style
@import "../../../styles/base/color";
@import "../../../styles/base/variable";
@import "../../../styles/base/header";
@import "../../../styles/base/menu";
@import "../../../styles/base/sideber";
@import "../../../styles/base/body";

// component style
@import "../../../styles/component/button";
@import "../../../styles/component/components";
@import "../../../styles/component/modal";

// module style
@import "../../../styles/module/search";
@import "../../../styles/module/dashboard";
@import "../../../styles/module/task.scss";

// responsive style
@import "../../../styles/responsive";

// Utilities
@import "../../../styles/utility";

.list-heading {
  color: $disavled-color;
  font-size: 13px;
  font-weight: $fw-regular;
  padding: 12px 12px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  line-height: 22px;

  i {
    margin-right: 7px;
  }
}

.task-listing {
  display: block;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    line-height: 24px;
    color: $light-blue3;
    vertical-align: middle;
    padding: 12px 12px;
    border-bottom: 1px solid #dee2e6;

    p {
      font-size: 13px;
      color: $blur-color-menu;
      margin-bottom: 0;
      line-height: 24px;
      font-weight: $fw-regular;
    }

    .student-name {
      font-size: 16px;
      color: $blur-color-menu;
      margin-bottom: 0;
      line-height: 24px;
      font-weight: $fw-semibold;
    }

    input:disabled ~ p {
      color: $light-blue3;
    }

    input:checked ~ p {
      font-weight: $fw-bold;
    }

    input:disabled ~ i {
      border-color: $disavled-color;
    }

    .task-date {
      max-width: 128px;
      position: relative;
      flex: 0 0 128px;
      color: $disavled-color;
    }
  }
}

@include responsive(sm) {
}

@include responsive(md) {
}

@include responsive(lg) {
}

@include responsive(xl) {
}

.overlay {
  min-width: 250px;

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bg-gray {
    background-color: #f8f9fa !important;
  }

  .dropdown-overlay-edit {
    padding: 4px 20px !important;
    cursor: pointer;

    i {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .dropdown-overlay-edit:hover {
    color: $green;
    background-color: #f8f9fa;
  }

  .dropdown-overlay-delete {
    padding: 4px 20px !important;
    cursor: pointer;

    i {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .dropdown-overlay-delete:hover {
    color: $red;
    background-color: #f8f9fa;
  }
}

.empty-tasks {
  font-size: 20px;
}

.doc {
  display: flex;
  align-items: center;

  p {
    color: $blur-color-menu;
    font-weight: $fw-semibold;
    font-size: 16px;
  }

  i {
    color: $green-color;
    font-size: 16px;
    margin-right: 10px;
  }
}

.download-button {
  color: $green-color !important;
}

.delete-button {
  color: $red !important;
}

.modal-title {
  margin-top: -0.3rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: $fw-semibold;
  color: $blur-color-menu;
}
.know-area {
  height: 186px;
  min-height: 120px;
  max-height: 186px;
  resize: none;
  max-width: 100%;
  line-height: 1.5715;
  vertical-align: bottom;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  width: 100%;
  overflow: auto;
}
.flotright {
  float: right;
}
.flotlft {
  float: left;
}
