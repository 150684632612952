.header-wrapper {
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $header-border-color;
}

.ham-menu {
  font-size: 22px;
  color: $green-color;
  cursor: pointer;
  margin-left: 25px;

  @include responsive(dsm) {
    display: block;
  }
  @include responsive(md) {
    display: none;
  }
}

.profile-menu {
  display: flex;
  align-items: center;

  a {
    color: $blur-color-menu;

    @include responsive(dsm) {
      font-size: 12px;
      font-family: $primary-font;
      font-weight: $fw-bold;
      line-height: 24px;
    }
    @include responsive(md) {
      font-size: 13px;
    }
  }

  &:after {
    border-top-color: $blur-color-menu;
    margin-left: 7px;
  }
}

.profile-pic {
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;

  @include responsive(dsm) {
    width: 30px;
    height: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@media (min-width: 486px) {
  .header-wrapper {
    padding: 11px 30px;
    height: 5.5rem !important;

    & .ant-select-single {
      width: 15rem;
    }
  }
}

@media (min-width: 330px) and (max-width: 485px) {
  .header-wrapper {
    padding: 11px 9px;
    height: 8.5rem !important;

    & .ant-select-single {
      position: absolute;
      width: 12rem;
      top: -3rem;
      left: 10rem;
    }
  }
  .profile-pic {
    width: 30px;
    height: 30px;
  }
  .profile-menu a {
    font-size: 12px;
  }
}
