@charset "utf-8";

// import variables
@import "../../../../styles/colors";
@import "../../../../styles/variables";

// base style
@import "../../../../styles/base/color";
@import "../../../../styles/base/variable";
@import "../../../../styles/base/header";
@import "../../../../styles/base/menu";
@import "../../../../styles/base/sideber";
@import "../../../../styles/base/body";

// component style
@import "../../../../styles/component/button";
@import "../../../../styles/component/components";
@import "../../../../styles/component/modal";

// module style
@import "../../../../styles/module/search";
@import "../../../../styles/module/dashboard";
@import "../../../../styles/module/task.scss";

// responsive style
@import "../../../../styles/responsive";

// Utilities
@import "../../../../styles/utility";

$yellow-color: #ffa62c;

thead .fixed-heading {
  background: $yellow-color !important;
  color: white !important;
}
thead .ant-table-selection-column {
  background: $yellow-color !important;
}

// override styles:
#dt-table table thead.ant-table-thead tr:nth-child(1) th {
  background: green;
  color: white !important;
}

#dt-table table thead.ant-table-thead tr:nth-child(2) th {
  background: #76bc97;
  color: white !important;
}

#dt-table table .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: green !important;
  color: white !important;
}
.ant-popover {
  width: 21%;
}
.popoverhight {
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding-top: 6px;
  padding-left: 5px;
}
.popoverhight:hover {
  background-color: #f5f5f5;
}
