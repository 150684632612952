.button-style {
  font-size: 13px;
  background: $gray-color;
  border-radius: 4px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: $fw-bold;
  cursor: pointer;

  &-green-btm {
    background: $green-color;
    color: $primary-color;

    &:hover {
      background: $deep-gray;
      color: $primary-color;
    }
  }

  &-gray-btm {
    background: $gray-color;
    color: $light-blue;

    &:hover {
      background: $green-color;
      color: $primary-color;
    }
  }

  &-normal-button {
    padding: 0 35px;
  }

  &-fullwidth-button {
    width: $full-width;
  }
}

.fullwidth-button {
  //width:$full-width;
  //color:$primary-color;
}

.green-btm {
  //background:$green-color;
  //color:$primary-color;

  &:hover {
    //background:$deep-gray;
    //color:$primary-color;
  }
}

.gray-btm {
  //background:$gray-color;
  //color:$light-blue;

  &:hover {
    //background:$green-color;
    //color:$primary-color;
  }
}

.icon-button {
  display: inline-block;
  color: $blur-color;
  font-size: 13px;
  font-weight: $fw-regular;
  text-transform: capitalize;
  line-height: 24px;

  span {
    margin-right: 14px;
  }

  &--iconcolor {
    span {
      font-size: 18px;
      color: $green-color;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.large-button {
  box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: $blur-color;
  font-size: 19px;
  text-transform: capitalize;
  font-weight: $fw-bold;
  line-height: 23px;
  padding: 16px 28px;
  display: inline-block;

  span {
    margin-right: 13px;
    color: $green-color;
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
  }
}
