@charset "utf-8";

// import variables
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";

// base style
@import "../../../../../styles/base/color";
@import "../../../../../styles/base/variable";
@import "../../../../../styles/base/header";
@import "../../../../../styles/base/menu";
@import "../../../../../styles/base/sideber";
@import "../../../../../styles/base/body";

// component style
@import "../../../../../styles/component/button";
@import "../../../../../styles/component/components";
@import "../../../../../styles/component/modal";

// module style
@import "../../../../../styles/module/search";
@import "../../../../../styles/module/dashboard";
@import "../../../../../styles/module/task.scss";

// responsive style
@import "../../../../../styles/responsive";

// Utilities
@import "../../../../../styles/utility";

.webinar-call-wrapper > div:nth-child(2) > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;

  //  Apply vertical margin on first child only
  & > div:first-child {
    margin: 10px 0;
  }

  & > div:not(:first-child) {
    margin: 10px;
  }
}

.OT_name {
  text-transform: capitalize;
}
