@charset "utf-8";

// import variables
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";

// base style
@import "../../../../../styles/base/color";
@import "../../../../../styles/base/variable";
@import "../../../../../styles/base/header";
@import "../../../../../styles/base/menu";
@import "../../../../../styles/base/sideber";
@import "../../../../../styles/base/body";

// component style
@import "../../../../../styles/component/button";
@import "../../../../../styles/component/components";
@import "../../../../../styles/component/modal";

// module style
@import "../../../../../styles/module/search";
@import "../../../../../styles/module/dashboard";
@import "../../../../../styles/module/task.scss";

// responsive style
@import "../../../../../styles/responsive";

// Utilities
@import "../../../../../styles/utility";

/*======================================================*/
/*==========  Tick Icons on Vertical Categories  ==========*/
/*======================================================*/

.categories-section {
  // tick/cross icon
  & .ant-steps-vertical {
    & .ant-steps-item-icon .ant-steps-icon {
      position: relative;
      top: -4.5px;
    }
  }

  & .ant-steps-item {
    background-color: #f2f2f2;
    margin: 4px;
    border-radius: 5px;
    width: 100%;
    height: 4.8rem;

    & .ant-steps-item-container {
      padding: 2px;
      margin-left: 10px;
      margin-top: 4px;

      & .ant-steps-item-tail {
        left: 28px !important;
      }

      & .ant-steps-item-description {
        font-size: 11px;
        opacity: 0.8;
        color: #6c757d !important;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .categories-section {
    & .ant-steps-item {
      width: 100%;
      height: 4.8rem;
    }
  }
}

.poll-question {
  & .ant-radio-wrapper {
    // Individual Poll Option
    background-color: #f2f2f2;
    border-radius: 5px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .poll-question {
    & .ant-radio-wrapper {
      // Individual Poll Option
      margin: 1.1rem 0 !important;
      padding: 1.7rem !important;
      height: auto;
      min-height: 6rem;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .poll-question {
    & .ant-radio-wrapper {
      // Individual Poll Option
      padding: 0.8rem !important;
      margin: 1rem !important;
      height: auto;
      min-height: 6rem;
    }
  }
}
