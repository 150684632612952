@charset "utf-8";

// import variables
@import "../../../../styles/colors";
@import "../../../../styles/variables";

// base style
@import "../../../../styles/base/color";
@import "../../../../styles/base/variable";
@import "../../../../styles/base/header";
@import "../../../../styles/base/menu";
@import "../../../../styles/base/sideber";
@import "../../../../styles/base/body";

// component style
@import "../../../../styles/component/button";
@import "../../../../styles/component/components";
@import "../../../../styles/component/modal";

// module style
@import "../../../../styles/module/search";
@import "../../../../styles/module/dashboard";
@import "../../../../styles/module/task.scss";

// responsive style
@import "../../../../styles/responsive";

// Utilities
@import "../../../../styles/utility";

.adviser-settings {
  .heading {
    font-weight: $fw-bold;
    font-size: 1.2rem;
    color: $green-color;
    margin-bottom: 30px;
  }

  .day {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .day-title {
    font-weight: 500;
    font-size: 1.1rem;
    color: $text-color;
    min-width: 130px;
    margin: 4px 6px;
  }

  .timepicker {
    margin-right: 6px;
  }

  .icon {
    background-color: $gray-color2;
    padding: 4px 10px;
    border-radius: 10px;
    margin: 4px 6px;
    color: $green-color;
  }

  .icon:hover {
    box-shadow: 0 0 6px $green-color;
  }
}

.leaves-list {
  border-top: 1px solid #e5e5e5;

  .leaves {
    border-bottom: 1px solid #e5e5e5;
    place-items: center;
  }

  .leaves-item {
    height: 4.5rem;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-size: 1.2rem;
      font-weight: $fw-medium;
      color: $blur-color;
    }

    .description {
      font-size: 1rem;
      color: $gray-color7;
      font-weight: $fw-medium;
    }
  }
}
