.two-factor-heading {
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 1.07142857rem !important;
}

#two-factor-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40vh;
  align-items: center;
}

.two-factor-form__pins {
  position: relative;
  left: 18px;

  input {
    color: $green;
    margin: 0 1px;
    text-align: center;
    line-height: 80px;
    font-size: 32px;
    font-weight: bold;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    width: 65%;
    height: 10vh !important;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;

    &:focus {
      border-color: $green;
      box-shadow: 0 0 5px $green inset;
    }

    &::selection {
      background: $green;
    }
  }
}
