@charset "utf-8";

// import variables
@import "../../../styles/colors";
@import "../../../styles/variables";

// base style
@import "../../../styles/base/color";
@import "../../../styles/base/variable";
@import "../../../styles/base/header";
@import "../../../styles/base/menu";
@import "../../../styles/base/sideber";
@import "../../../styles/base/body";

// component style
@import "../../../styles/component/button";
@import "../../../styles/component/components";
@import "../../../styles/component/modal";

// module style
@import "../../../styles/module/search";
@import "../../../styles/module/dashboard";
@import "../../../styles/module/task.scss";

// responsive style
@import "../../../styles/responsive";

// Utilities
@import "../../../styles/utility";

.login-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: none;
  padding-left: 15px;
  padding-right: 15px;

  @include responsive(betweensm) {
    height: 100vh;
    background-position-x: center;
    background-position-y: center;
    background-image: url(../../../assets/images/login-photo.png);
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: auto;
  }
  @include responsive(dsm) {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: auto;
  }

  @include responsive(md) {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 100vh;
  }
}

.login-wrapper-left {
  @include responsive(sm) {
    min-height: auto;
  }
  @include responsive(betweensm) {
    display: none !important;
  }

  @include responsive(md) {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 100vh;
  }
}

.login-bg {
  background: linear-gradient(135.01deg, #051b72 0%, #00955d 100%);
}

.login-holder {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  @include responsive(dsm) {
    flex-direction: column;
    padding-top: 30px;
  }

  @include responsive(md) {
    padding-top: 0px;
    justify-content: space-between;
  }
  @include responsive(xl) {
    justify-content: center;
  }
  @include responsive(betweensm) {
    height: auto;
    background-color: #fff;
    padding: 80px 20px;
    border-radius: 20px;
  }
}

.login-inner {
  max-width: 348px;
  margin: 0 auto;
  width: 100%;
  @include responsive(betweensm) {
    max-width: 300px;
  }

  figure {
    @include responsive(dsm) {
      margin-bottom: 25px;
      text-align: center;
    }
    @include responsive(md) {
      margin-bottom: 35px;
    }
    @include responsive(lg) {
      margin-bottom: 64px;
    }
    img {
      @include responsive(dsm) {
        width: 100% !important;
        height: 100% !important;
      }
      @include responsive(md) {
        width: 60% !important;
        height: 60% !important;
      }
      @include responsive(lg) {
        width: 60% !important;
        height: 60% !important;
      }
    }
  }
  .footer-link {
    figure {
      @include responsive(dsm) {
        margin-bottom: 25px;
        text-align: center;
      }
      @include responsive(md) {
        margin-bottom: 35px;
      }
      @include responsive(lg) {
        margin-bottom: 64px;
      }
      img {
        @include responsive(dsm) {
          width: 60% !important;
          height: 60% !important;
        }
        @include responsive(md) {
          width: 40% !important;
          height: 40% !important;
        }
        @include responsive(lg) {
          width: 40% !important;
          height: 40% !important;
        }
      }
    }
  }

  h1 {
    @include responsive(dsm) {
      margin-bottom: 20px;
    }
    @include responsive(md) {
      margin-bottom: 20px;
    }
    @include responsive(lg) {
      margin-bottom: 20px;
    }
  }

  @include responsive(dsm) {
    margin-bottom: 25px;
  }

  @include responsive(md) {
    margin-bottom: 0px;
  }
  .page-heading {
    font-size: 30px;
    line-height: 24px;
    color: #5f5d56;
    font-weight: 700;
    text-align: left;
    text-transform: none;
  }
  .button-style-fullwidth-button {
    width: 16%;
  }
  .update-password {
    width: 34%;
  }
  .update-password-h4 {
    font-size: 20px;
  }

  .forget-link {
    display: inline-block;
    font-size: 13px;
    color: #68665f;
    line-height: 24px;
    font-weight: $fw-bold;
    padding-left: 24px;
    @include responsive(dsm) {
      /* position: static;*/
    }

    @include responsive(lg) {
      /*position: absolute;*/
    }
  }
  .help-link {
    display: inline-block;
    font-size: 13px;
    color: #4eab76;
    line-height: 24px;
    font-weight: $fw-bold;
    text-align: left;
    padding-top: 10px;
  }
  .social-div {
    padding-top: 16px;
    width: 100%;
    text-align: left;
  }
  .footer-link {
    padding-top: 36px;
    text-align: left;
  }
  .powered-by {
    text-align: left;
    color: #acaba7;
    margin-bottom: 0em;
    font-size: 12px;
    line-height: 14px;
  }
  input[type="text"],
  input[type="password"],
  input[type="email"],
  select {
    border: 1.5px solid #f5bf70;
  }
}

.input-style {
  width: $full-width;
}

.form-group-custom {
  margin-bottom: 19px;
}

.form-footer {
  padding-top: 7px;

  .text-divider {
    display: block;
    margin: 19px 0;
    font-size: 11px;
    line-height: 24px;
    color: $light-blue2;
  }
}

// Google/Microsoft Login Buttons:
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  min-width: 150px;
  outline: none;
  border: none;
  height: 40px;
  padding-bottom: 12px;
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  border: 1.5px solid #f5bf70;

  .google-icon-wrapper {
    position: absolute;
    margin-top: 3px;
    margin-left: 3px;
    width: 40px;
    height: 34px;
    border-radius: 2px;
    background-color: $white;
  }

  .google-icon {
    margin: 9px 9px;
    width: 18px;
    height: 16px;
  }

  .btn-text {
    margin: 9px 9px 0 0;
    color: #75756f;
    text-align: left;
    font-weight: $fw-bold;
    font-size: 14px;
    letter-spacing: 0.2px;
    padding-left: 45px;
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }

  &:active {
    background: $button-active-blue;
  }

  &:after {
    outline: none;
  }
}

.microsoft-btn {
  min-width: 150px;
  outline: none;
  border: none;
  height: 40px;
  padding-bottom: 12px;
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  border: 1.5px solid #f5bf70;

  .google-icon-wrapper {
    position: absolute;
    margin-top: 3px;
    margin-left: 3px;
    width: 40px;
    height: 34px;
    border-radius: 2px;
    background-color: $white;
  }

  .google-icon {
    margin: 9px 9px;
    width: 18px;
    height: 16px;
  }

  .btn-text {
    margin: 9px 9px 0 0;
    color: #75756f;
    text-align: left;
    font-weight: $fw-bold;
    font-size: 14px;
    letter-spacing: 0.2px;
    padding-left: 45px;
  }

  &:hover {
    box-shadow: 0 0 6px $green-color;
  }

  &:active {
    background: $green-color;
  }

  &:after {
    outline: none;
  }
}
