.cursor-pointer {
  cursor: pointer !important;
}

.overflow-hidden {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.w-100 {
  width: 100% !important;
}
