.dashboard-wrapper {
  background: $secondary-color;
  min-height: 100vh;
}

.dashboard-body-wrapper {
  display: flex;
  height: calc(100vh - 70px);

  // flex-wrap: wrap;

  &.overflow-cention {
    overflow: hidden;
  }
}

.content-panel {
  flex: 1;
  padding: 45px 0;
  max-width: 100%;
  transition: all 0.4s ease-in-out;

  h1 {
    margin-bottom: 43px;

    @include responsive(dsm) {
      margin-bottom: 43px;
    }
  }

  &.left-aside {
    margin-left: 250px;
  }
}

.select-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-drop {
  background: $gray-color4;
  border-radius: 3.2px;
  cursor: pointer;
  max-width: 28px;
  height: 20px;
  width: 100%;
  text-align: center;
  line-height: 20px;
  color: $disavled-color;
}

.page-heading {
  font-size: 31px;
  line-height: 24px;
  color: $blur-color;
  text-transform: capitalize;
  font-weight: $fw-bold;
}

.page-sub-heading {
  color: $blur-color;
  font-size: 19px;
  line-height: 23px;
  font-weight: $fw-bold;

  span {
    color: $green-color;
  }
}

.small-heading {
  color: $green-color;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  font-weight: $fw-regular;
  line-height: 15px;
}

@include responsive(sm) {
}

@include responsive(md) {
  .content-panel {
    flex: 0 0 67%;
    max-width: 67%;
    padding: 45px;
  }
  .tabs-dt {
    flex: 0 0 83%;
    width: 83% !important;
  }
}

@include responsive(lg) {
  .content-panel {
    flex: 1;
    max-width: 100%;
  }
  .tabs-dt {
    flex: 1;
    width: 100%;
  }
}

.tabs-dt {
  width: 100%;
}

@include responsive(xl) {
}
