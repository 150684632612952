@charset "utf-8";

// import variables
@import "../../../styles/colors";
@import "../../../styles/variables";

// base style
@import "../../../styles/base/color";
@import "../../../styles/base/variable";
@import "../../../styles/base/header";
@import "../../../styles/base/menu";
@import "../../../styles/base/sideber";
@import "../../../styles/base/body";

// component style
@import "../../../styles/component/button";
@import "../../../styles/component/components";
@import "../../../styles/component/modal";

// module style
@import "../../../styles/module/search";
@import "../../../styles/module/dashboard";
@import "../../../styles/module/task.scss";

// responsive style
@import "../../../styles/responsive";

// Utilities
@import "../../../styles/utility";

.loader-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lds-ellipsis {
  width: 64px;
  height: 64px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $green;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
