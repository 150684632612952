html,
body {
  margin: 0;
  padding: 0;
  font-size: $p;
  font-weight: $fw-regular;
  font-family: $primary-font;
  background: $secondary-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0;
  padding: 0;
  font-weight: unset;
  line-height: 1;
  font-family: $primary-font;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

p {
  font-size: $p;
  padding: 0;
  line-height: 1.5;
}

img {
  max-width: 100%;
}

a,
img {
  border: 0;
  text-decoration: none;
  outline: none;
}

a,
a:link,
a:visited,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease 0s;
}

a:hover {
  color: $green-color;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.bullets {
    list-style: disc;
    margin: 10px 30px;
  }
}

input,
textarea,
select {
  font-size: $h6;
  outline: none;
}

input[type="submit"] {
  transition: all 0.3s ease 0s;
  outline: none;
  border: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 36px;
  border: 1.5px solid $input-border-color;
  background: $primary-color;
  border-radius: 4px;
  padding: 0 18px;
  font-family: $primary-font;
}

::-webkit-input-placeholder {
  color: $disavled-color;
  font-size: 13px;
  font-family: $primary-font;
}

:-ms-input-placeholder {
  color: $disavled-color;
  font-size: 13px;
  font-family: $primary-font;
}

::placeholder {
  color: $disavled-color;
  font-size: 13px;
  font-family: $primary-font;
  letter-spacing: 0;
  line-height: 24px;
}

figure {
  margin: 0;
}

.labelstyle {
  color: $green-color;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $fw-regular;
  line-height: 15px;
  margin-bottom: 9.5px;
  display: block;
}

.select-style {
  width: 100%;
  background: $primary-color url("/src/assets/images/select-dropdown.jpg")
    no-repeat 92% center;
  appearance: none;

  &-medium-width {
    max-width: 222px;
    width: 100%;
  }

  &--without-outline {
    border: none;
    padding: 0 0 0 10px;
    font-size: 12px;
    width: auto;
    min-width: 100%;
  }
}

.space-between {
  @include dflex-between;
}
