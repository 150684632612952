.left-menu {
  display: block;
  text-align: left;
  background-color: $primary-color;
  width: 100%;
  max-width: 100%;
  flex: 1 0 0%;
  position: inherit;

  li {
    display: block;

    &:hover {
      background: $menu-green;
    }

    a {
      display: flex;
      align-items: center;
      padding: 11px 30px;
      color: $blur-color-menu;
      font-size: 13px;
      font-weight: $fw-regular;
      line-height: 24px;
      position: relative;
      transition: all 0.6s ease-in-out;
      width: 100%;
      font-family: $primary-font;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $green-color;
        width: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: all 0.3s ease-in-out;
      }

      span {
        display: inline-block;
        margin-right: 18px;
        font-size: 21px;
        color: $gray-color6;
      }

      &:hover {
        background: $menu-green;
        color: $green-color;

        &:after {
          width: 5px;
        }

        &:hover span {
          color: $green-color;
        }
      }
    }

    .selected {
      background: $menu-green;
      color: $green-color;

      &:after {
        width: 5px;
      }

      span {
        color: $green-color;
      }
    }

    .bg-green {
      background: $menu-green;

      a {
        &:hover {
          background: #d9ebe4;
        }
      }
    }

    ul {
      left: 100%;
      top: 0;
      height: 100%;
      max-width: 250px;
      width: 100%;
      padding: 31px 0 0;
      display: none;
      z-index: 6;

      @include responsive(dsm) {
        position: static;
      }

      @include responsive(md) {
        position: absolute;
      }
    }

    &:hover ul {
      display: block;
    }
  }
}

.student-left-menu {
  display: block;
  text-align: left;
  background-color: $primary-color;
  width: 100%;
  max-width: 100%;
  flex: 1 0 0%;
  position: inherit;

  li {
    display: block;

    &:hover {
      background: $menu-gray;
    }

    i {
      color: gray;
    }

    a {
      display: flex;
      align-items: center;
      padding: 11px 30px;
      color: #6c5e54;
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      position: relative;
      transition: all 0.6s ease-in-out;
      width: 100%;
      font-family: $primary-font;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $yellow-primary;
        width: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: all 0.3s ease-in-out;
      }

      span {
        display: inline-block;
        margin-right: 18px;
        font-size: 21px;
        color: #6c5e54;
        font-weight: 500;
      }

      &:hover {
        background: $menu-gray;

        &:after {
          width: 5px;
        }

        &:hover span {
          color: #6c5e54;
        }
      }
    }

    .selected {
      background: $menu-gray;
      color: #6c5e54;

      &:after {
        width: 5px;
      }

      span {
        color: $yellow-primary;
      }
    }

    &:hover ul {
      display: block;
    }
  }
}
