@charset "utf-8";

// import variables
@import "../../../styles/colors";
@import "../../../styles/variables";

// base style
@import "../../../styles/base/color";
@import "../../../styles/base/variable";
@import "../../../styles/base/header";
@import "../../../styles/base/menu";
@import "../../../styles/base/sideber";
@import "../../../styles/base/body";

// component style
@import "../../../styles/component/button";
@import "../../../styles/component/components";
@import "../../../styles/component/modal";

// module style
@import "../../../styles/module/search";
@import "../../../styles/module/dashboard";
@import "../../../styles/module/task.scss";

// responsive style
@import "../../../styles/responsive";

// Utilities
@import "../../../styles/utility";

.appointment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 25px 0;
  margin-bottom: 65px;
}

.o-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-heading {
    color: $blur-color;
    font-size: 19px;
    white-space: nowrap;
    line-height: 23px;
    text-transform: capitalize;
    font-weight: $fw-bold;

    span {
      color: $green-color;
    }
  }

  .pagination {
    max-width: 76px;
    flex: 0 0 76px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 25px;
    border: 1px solid $gray-color2;
    border-radius: 4px;
    padding: 0 8px;

    .prev,
    .next {
      color: $light-blue3;
      font-size: 16px;

      &:hover {
        color: $green-color;
      }
    }
  }
}

.o-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60px;
  flex: 0 0 60px;

  .list-view,
  .calendar-view {
    color: $light-blue4;
    font-size: 23px;

    &.active {
      color: $green-color;
    }
  }
}

.calender-wrap {
  margin-bottom: 25px;
}

.timezone-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.current-date {
  font-size: 19px;
  line-height: 23px;
  color: $gray-color7;
  display: block;
  margin-bottom: 20px;
  font-weight: $fw-regular;
}

.time-slot-list {
  min-height: 100px;
  max-height: 360px;
  overflow-y: auto;

  li {
    display: block;
    margin-right: 4px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .slot {
      display: block;
      color: $blur-color3;
      font-size: 16px;
      text-align: center;
      border: 1px solid $blur-color3;
      border-radius: 4px;
      font-weight: $fw-regular;

      @include responsive(dsm) {
        width: 100%;
        padding: 10px 0;
      }
    }

    .selected-slot {
      display: block;
      background-color: $blur-color3;
      color: $primary-color;
      font-size: 16px;
      text-align: center;
      border: 1px solid $blur-color3;
      border-radius: 4px;
      font-weight: $fw-regular;

      @include responsive(dsm) {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}

.success-icon {
  font-size: 35px;
  color: $green;
  margin-bottom: 16px;
  display: block;
}

.error-icon {
  font-size: 35px;
  color: red;
  margin-bottom: 16px;
  display: block;
}

.success-message {
  @include responsive(dsm) {
    padding: 49px 0;
  }

  @include responsive(lg) {
    padding: 52px 73px;
  }

  h3 {
    color: $blur-color;
    font-weight: $fw-bold;
  }
}

.message-details {
  font-size: 19px;
  line-height: 31px;
  font-weight: $fw-regular;

  @include responsive(dsm) {
    padding: 49px 0;
  }

  @include responsive(lg) {
    padding: 52px 73px;
  }
}

.border-right-side {
  @include responsive(dsm) {
    border-right: 0;
    border-bottom: 1px solid $gray-color;
  }

  @include responsive(lg) {
    border-right: 1px solid $gray-color;
    border-bottom: 0;
  }
}
.pn-msg {
  display: -webkit-box !important;
}
.pn-msg__content {
  word-break: break-all;
}
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .pn-msg-list {
  }
  .pn-msg-list-scroller {
    width: 94%;
  }
  .dashboard-body-wrapper {
    display: flex;
    height: 100vh;
  }
  .overflow-y-auto {
    overflow-y: none;
    width: 100%;
  }
}
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .pn-msg-list {
  }
  .pn-msg-list-scroller {
    width: 94%;
  }
  .dashboard-body-wrapper {
    display: flex;
    height: 100vh;
  }
  .overflow-y-auto {
    overflow-y: none;
    width: 100%;
  }
}
.overflow-y-auto {
  overflow-y: unset;
  width: calc(100% - 242px);
}
.dashboard-body-wrapper {
  display: flex;
  height: calc(90vh - 70px) !important;
}
